export const fileExtensions = [
  'ACC',
  'AE',
  'AI',
  'AN',
  'AVI',
  'BMP',
  'CSS',
  'CSV',
  'DAT',
  'DGN',
  'DOC',
  'DOCH',
  'DOCM',
  'DOCX',
  'DOTH',
  'DW',
  'DWFX',
  'DWG',
  'DXF',
  'DXL',
  'EML',
  'EPS',
  'F4A',
  'F4V',
  'FLV',
  'FS',
  'GIF',
  'HTML',
  'IND',
  'JPEG',
  'JPG',
  'JPP',
  'JS',
  'JSX',
  'LOG',
  'LR',
  'M4A',
  'M4V',
  'MBOX',
  'MDB',
  'MIDI',
  'MKV',
  'MOV',
  'MP3',
  'MP4',
  'MPEG',
  'MPG',
  'MPP',
  'MPT',
  'MPW',
  'MPX',
  'MSG',
  'ODS',
  'OGA',
  'OGG',
  'OGV',
  'ONE',
  'OST',
  'PDF',
  'PHP',
  'PNG',
  'POT',
  'POTH',
  'POTM',
  'POTX',
  'PPS',
  'PPSX',
  'PPT',
  'PPTH',
  'PPTM',
  'PPTX',
  'PREM',
  'PS',
  'PSD',
  'PST',
  'PUB',
  'PUBH',
  'PUBM',
  'PWZ',
  'RAR',
  'RAW',
  'RP',
  'RTF',
  'SQL',
  'SVG',
  'SWF',
  'TIF',
  'TIFF',
  'TS',
  'TSX',
  'TXT',
  'URL',
  'VCF',
  'VDX',
  'VOB',
  'VSD',
  'VSS',
  'VST',
  'VSX',
  'VTX',
  'WAV',
  'WDP',
  'WEBM',
  'WMA',
  'WMV',
  'XD',
  'XLS',
  'XLSM',
  'XLSX',
  'XML',
];
